import { DEFAULT_COOKIE } from "../../constants/cookie-constants";
import {
  CUSTOMIZE_CANCEL_BTN_ID,
  CUSTOMIZE_SAVE_BTN_ID,
} from "../../constants/ux-constants";
import { getConsentCookie as getConsentCookieCore } from "../../cookie";
import CookieDefinitions from "../../definitions/cookie-definitions";
import UXDefinitions from "../../definitions/ux-definitions";
import { act } from "../../jsx-act";
import Button from "../utilities/button";
import SpaceBetween from "../utilities/space-between";
import { isChecked } from "../ux-components";

interface ContentFooterProps {
  handleSaveClick: UXDefinitions.HandleSaveClick;
  handleCancelClick: UXDefinitions.HandleCancelClick;
  localizedText: UXDefinitions.ConsentSelectorLocalization;
  getConsentCookie: () => ReturnType<typeof getConsentCookieCore>;
}
export default ({
  handleSaveClick,
  handleCancelClick,
  localizedText,
  getConsentCookie,
}: ContentFooterProps) => {
  const getCookie = () => {
    return getConsentCookie() || DEFAULT_COOKIE;
  };
  function getConsentStateFromDom(): CookieDefinitions.ConsentCookie {
    const cookie = getCookie();
    return {
      essential: true,
      performance: isChecked("performance"),
      functional: isChecked("functional"),
      advertising: isChecked("advertising"),
      ccba: cookie["ccba"] === false ? false : true,
    };
  }

  function saveClicked() {
    handleSaveClick(getConsentStateFromDom(), "preferencesModal");
  }

  function cancelClicked() {
    handleCancelClick("preferencesModal");
  }

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button
        dataId={CUSTOMIZE_CANCEL_BTN_ID}
        variant="secondary"
        onClick={cancelClicked}
        props={{ "aria-label": localizedText["button-cancel-aria-label"] }}
      >
        {localizedText["button-cancel"]}
      </Button>
      <Button
        dataId={CUSTOMIZE_SAVE_BTN_ID}
        variant="primary"
        onClick={saveClicked}
        props={{ "aria-label": localizedText["button-save-aria-label"] }}
      >
        {localizedText["button-save"]}
      </Button>
    </SpaceBetween>
  );
};
