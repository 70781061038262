import { TABTRAP_ID } from "../../constants/ux-constants";
import { act, JSX } from "../../jsx-act";
import './modal.scss';

interface ModalProps extends Record<string, unknown> {
  id: string;
  isRTL?: boolean;
  children?: JSX.Child;
  footer?: JSX.Child;
  header?: JSX.Child;
  size?: "large";
  onDismiss?: () => void;
  darkModeEnabled?: boolean;
}

/**
 * The purpose of this function is to keep users in the modal when
 * moving Tab keystrokes.
 */
const handleOutsideTabTrap = (event: KeyboardEvent) => {
  const isTabPressed = event.key === "Tab";
  const modalContainer = document.querySelector<HTMLElement>(
    `div[data-id=${TABTRAP_ID}][tabindex="0"]`
  );

  if (isTabPressed && modalContainer) {
    const focusableElements = modalContainer.querySelectorAll(
      'a[href], button, input, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusableElement = focusableElements[0] as HTMLElement;
    const lastFocusableElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    if (event.shiftKey) {
      // If shift key is pressed, move focus to the last focusable element
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
      } else {
        (document.activeElement as HTMLElement).blur();
      }
    } else {
      // If shift key is not pressed, move focus to the first focusable element
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        event.preventDefault();
      } else {
        (document.activeElement as HTMLElement).blur();
      }
    }
  }
};

export default ({
  id,
  isRTL = false,
  children,
  header,
  footer,
  size = "large",
  onDismiss = () => null,
  darkModeEnabled = false,
  ...props
}: ModalProps) => {
  const handleEscapeKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      const selfContainer = document.querySelector(`[data-id="${id}"]`) as HTMLElement | null;
      if (selfContainer?.style.display !== 'none') {
        event.stopPropagation();
        onDismiss();
      }
    }
  };

  return (
    <div
      id={id}
      data-id={id}
      style={{ display: 'none' }}
      tabIndex={0}
      class={darkModeEnabled ? 'dark-mode-enabled' : ''}
      onKeyDown={handleEscapeKeyPress}
    >
      <div
        onClick={onDismiss}
        {...props}
        class={`awsccc-u-modal-container ${props.class || ''}`}
      >
        <div
          class={`awsccc-u-modal awsccc-u-modal-${size}`}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`awsccc-u-modal-header-${id}`}
          dir={isRTL ? "rtl" : "ltr"}
          data-awsccc-modal-toggle
          data-id={TABTRAP_ID}
          tabIndex={-1}
          onKeyDown={handleOutsideTabTrap}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        >
          <div class="awsccc-u-modal-header">
            <h2 id={`awsccc-u-modal-header-${id}`}>{header}</h2>
          </div>
          <div class="awsccc-u-modal-content">{children}</div>
          {footer ? <div class="awsccc-u-modal-footer">{footer}</div> : null}
        </div>
        <div class="awsccc-u-modal-backdrop" />
      </div>
    </div>
  );
};
