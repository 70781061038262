import { CURRENT_MODAL_DATA_ATTR, MODAL_OPEN_BODY_CLASS, TABTRAP_ID } from "../../constants/ux-constants";
import UXDefinitions from "../../definitions/ux-definitions";
import { convertToArray } from "../../utils";

const modalTriggerElements: Record<string, HTMLElement | null> = {};

// Return false if the element or any of its parents cannot be focused
const isElementFocusable = (el: HTMLElement | null) => {
  if (el === document.documentElement) {
    return true;
  }

  if (!el) {
    return false;
  }

  const negativeTabIndex = parseInt(el.getAttribute('tabindex') || '0') < 0;
  const style = getComputedStyle(el);
  const hidden = style.getPropertyValue('display') === 'none' || style.getPropertyValue('visibility') === 'hidden';

  if (negativeTabIndex || hidden) {
    return false;
  }

  return isElementFocusable(el.parentElement);
}

/** Open the modal specified by `modalId`. */
export const openModal: UXDefinitions.OpenModal = (modalId: string) => {
  const modal = document.querySelector(`[data-id="${modalId}"]`);
  if (!modal || !(modal instanceof HTMLElement)) {
    return;
  }

  // Set the element that was focused prior to opening the modal
  if (document.activeElement instanceof HTMLElement) {
    modalTriggerElements[modalId] = document.activeElement;
  }
  document.body.classList.add(MODAL_OPEN_BODY_CLASS);
  document.body.setAttribute(CURRENT_MODAL_DATA_ATTR, modalId);

  modal.style.display = "block";

  /**
   * When the customize modal is closed, by default
   * we set the tab traps to -1 to prevent users from
   * tabbing to the modal while it is closed. When users
   * open the modal, we set the tabindex to 0 to make contents
   * focusable. We also default focus to the first element in the modal.
   */
  const nodes = modal.querySelectorAll(`div[data-id=${TABTRAP_ID}]`);
  convertToArray(nodes).forEach(function (el: HTMLElement, i: number) {
    if (i === 0) {
      el.focus({ preventScroll: true });
    }
    el.setAttribute("tabindex", "0");
  });
};

/** Close the modal specified by `modalId`. */
export const closeModal: UXDefinitions.CloseModal = (modalId: string) => {
  const modal = document.querySelector(`[data-id="${modalId}"]`);
  if (!modal || !(modal instanceof HTMLElement)) {
    return;
  }

  document.body.classList.remove(MODAL_OPEN_BODY_CLASS);
  document.body.removeAttribute(CURRENT_MODAL_DATA_ATTR);

  modal.style.display = "none";

  /**
   * Ensures that the modal cannot be tabbed when
   * the modal is closed.
   */
  const nodes = modal.querySelectorAll(`div[data-id=${TABTRAP_ID}]`);
  convertToArray(nodes).forEach(function (el) {
    el.setAttribute("tabindex", "-1");
  });

  // Return focus to the element that was initially focused
  if (isElementFocusable(modalTriggerElements[modalId])) {
    modalTriggerElements[modalId]?.focus();
  }
  delete modalTriggerElements[modalId];
};
